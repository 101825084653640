import axios from "@/utils/axios";

import apiPath from "@/utils/apiPath";
const inspection = apiPath.inspection;

export * from "./list-op";

export const getInspectionRecordList = params => {
  return axios.get(`${inspection}/page/patrol-records`, { params });
};
// app端 巡检记录分页接口
export const getPatrolRecordsList = params => {
  // terminalFlag终端标识： 0-pc， 1-app
  params = { ...params, terminalFlag: 1 };
  return axios.get(`${inspection}/page/app/patrol-records`, { params });
};

// 巡检记录数量统计
export const getPatrolRecordsCount = params => {
  return axios.get(`${inspection}/count/patrol-records`, { params });
};

// 补录页面下拉接口
export const getSupplementStatusSelect = params => {
  return axios.get(`${inspection}/select/record-supplement-status`, { params });
};
