import axios from "@/utils/axios";

import apiPath from "@/utils/apiPath";
const inspection = apiPath.inspection;

export const getCountInspect = params => {
  return axios.get(`${inspection}/count/patrol-records`, { params });
};

export function getSelectSupplementStatus(params) {
  return axios.get(`${inspection}/select/record-supplement-status`, {
    params
  });
}
export const approvalInspectSupplement = data => {
  return axios.post(
    `${inspection}/approval/record-supplement/${data.id}`,
    data
  );
};

export function getSupplementAuth(id) {
  return axios.get(`${inspection}/permission/record-supplement/${id}`);
}
