<template>
  <div>
    <van-nav-bar
      :border="false"
      :right-text="+pageInfo.supplementStatus === 3 ? '完成补录' : ''"
      fixed
      left-arrow
      title="巡检任务"
      @click-left="$router.go(-1)"
      @click-right="onClickRight"
    />
    <div class="content">
      <div class="note">任务即将超时(剩余{{ remainTime }}min)，请尽快完成!</div>
      <div style="padding: 0 16px">
        <div class="title-box">
          <div class="left-box">
            <span class="title">{{ pageInfo.name }}</span>
            <span v-if="pageInfo.overtimeStatus" class="overtime-tag"
              >超期</span
            >
            <span v-if="pageInfo.supplementFlag" class="make-up-tag">补录</span>
          </div>
          <div :class="statusClassSwitch" class="status-box">
            {{ pageInfo.recordStatusName }}
          </div>
        </div>
        <span class="date-box">{{
          `${pageInfo.validStartTime || "--:--:--"} - ${pageInfo.validEndTime ||
            "--:--:--"} ${pageInfo.patrolEndTimeFlagName}`
        }}</span>
        <div :class="{ 'pack-up': baseInfoPackUp }" class="detail-box">
          <van-form ref="baseForm" class="base-form">
            <div class="detail-item">
              <span class="label">所属部门：</span>
              <span class="text">{{ pageInfo.departmentName }}</span>
            </div>
            <div class="detail-item">
              <span class="label">执行班组：</span>
              <span class="text">{{ pageInfo.shiftGroupName }}</span>
            </div>
            <div class="detail-item">
              <span class="label">任务类型：</span>
              <span class="text">{{ pageInfo.taskTypeName }}</span>
            </div>
            <div class="detail-item">
              <span class="label">巡检周期：</span>
              <span class="text">{{ cycleUnintsSwitch }}</span>
            </div>
            <template v-if="pageInfo.recordStatus !== 0">
              <div class="detail-item">
                <span class="label">开始时间：</span>
                <span class="text">{{
                  pageInfo.actualStartTime || "--:--:--"
                }}</span>
              </div>
              <div class="detail-item">
                <span class="label">结束时间：</span>
                <span class="text">{{
                  pageInfo.actualEndTime || "--:--:--"
                }}</span>
              </div>
              <div class="detail-item">
                <span class="label">执行人：</span>
                <span class="text executor">{{
                  pageInfo.executePersonName
                }}</span>
                <span class="switch-account" @click="switchAccountClick"
                  >切换账号</span
                >
              </div>
              <div class="detail-item">
                <span class="label">同行人：</span>
                <span class="text associate">
                  <!-- :value="" -->
                  <!-- @click="associatePopupVisible = true"  is-link -->
                  <van-field
                    arrow-direction="down"
                    readonly
                    @click="associateClick()"
                  >
                    <template #input>
                      {{
                        pageInfo.partnerName
                          ? pageInfo.partnerName.replace(/,/g, "、")
                          : ""
                      }}
                    </template>
                  </van-field>
                </span>
              </div>
            </template>
            <template v-if="pageInfo.supplementStatus === 3">
              <div v-if="pageInfo.recordStatus === 2" class="detail-item">
                <span class="label">补录时间：</span>
                <span class="text">{{
                  pageInfo.supplementTime || "--:--:--"
                }}</span>
              </div>
              <van-field
                :border="false"
                :rules="[{ required: true, message: '请补充实际开始时间' }]"
                :value="pageInfo.actualStartTime"
                class="c-van-field"
                label="实际开始时间："
                label-class="c-label"
                label-width="120px"
                name="datetimePicker"
                placeholder="点击选择时间"
                readonly
                required
              >
                <template #input>
                  <date-time-picker-popup
                    v-if="!pageInfo.actualStartTime"
                    v-model="pageInfo.actualStartTime"
                    :time-close="false"
                    class="c-data-tiem"
                    format="{y}-{m}-{d} {h}:{i}:00"
                    type="datetime"
                  ></date-time-picker-popup>
                </template>
              </van-field>
              <van-field
                :border="false"
                :rules="[{ required: true, message: '请补充实际结束时间' }]"
                :value="pageInfo.actualEndTime"
                class="c-van-field"
                label="实际结束时间："
                label-class="c-label"
                label-width="120px"
                name="datetimePicker"
                placeholder="点击选择时间"
                readonly
                required
              >
                <template #input>
                  <date-time-picker-popup
                    v-if="!pageInfo.actualEndTime"
                    v-model="pageInfo.actualEndTime"
                    :time-close="false"
                    class="c-data-tiem"
                    format="{y}-{m}-{d} {h}:{i}:00"
                    type="datetime"
                  ></date-time-picker-popup>
                </template>
              </van-field>
            </template>
          </van-form>
        </div>
        <div v-if="pageInfo.recordStatus !== 0" class="exp-box">
          <van-icon
            v-show="baseInfoPackUp"
            color="#ccc"
            name="arrow-down"
            size="18"
            @click="baseInfoPackUp = false"
          />
          <van-icon
            v-show="!baseInfoPackUp"
            color="#ccc"
            name="arrow-up"
            size="18"
            @click="baseInfoPackUp = true"
          />
        </div>
      </div>
    </div>

    <section class="list-box">
      <div v-for="(item, idx) of taskList" :key="item.id" class="">
        <div class="item" @click="toDetail(item)">
          <div class="index-box">
            <div class="path-box">
              <span
                v-if="pageInfo.sortedStatus && idx !== 0"
                :class="{ 'checked-path': topPathSwitch(idx) }"
                class="path"
              ></span>
            </div>
            <div
              :class="{
                'checked-radius': item.checked,
                'current-radius': pageInfo.sortedStatus && item.current
              }"
              class="index-radius"
            >
              {{ idx + 1 }}
            </div>
            <div class="path-box">
              <span
                v-if="pageInfo.sortedStatus && idx !== taskList.length - 1"
                :class="{ 'checked-path': item.checked }"
                class="path"
              ></span>
            </div>
          </div>
          <div class="task-info-box">
            <div class="name-box">
              <span class="name">{{ item.pointName }}</span>
              <span>
                <van-icon
                  class="iconfont-Line"
                  class-prefix="iconfont"
                  name="iconfont-Line"
                  size="14"
                />
              </span>
            </div>
            <div class="date-box">
              <span v-if="item.startTime">{{
                `${item.startTime || "--:--:--"} - ${item.endTime ||
                  "--:--:--"}`
              }}</span>
              <span v-else>--</span>
            </div>
            <div class="item-num-box">
              <div class="total num-style">
                总检查项：<span class="item-num">{{ item.totalNum }}</span>
              </div>
              <div class="normal num-style">
                正常：<span class="item-num">{{
                  checkItemNumSwitch(item.normalNum, item.startTime)
                }}</span>
              </div>
              <div
                :class="{
                  active:
                    item.startTime &&
                    item.abnormityNum &&
                    item.abnormityNum !== '0'
                }"
                class="abnormal num-style"
              >
                <label>
                  异常：<span class="item-num">{{
                    checkItemNumSwitch(item.abnormityNum, item.startTime)
                  }}</span>
                </label>
              </div>
              <div
                :class="{
                  active: item.startTime && item.leakNum && item.leakNum !== '0'
                }"
                class="leakage num-style"
              >
                <label>
                  漏检：<span class="item-num">{{
                    checkItemNumSwitch(item.leakNum, item.startTime)
                  }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="item-path">
          <span
            v-if="pageInfo.sortedStatus && idx !== taskList.length - 1"
            :class="{ 'checked-path': item.checked }"
            class="path"
          ></span>
        </div>
      </div>
    </section>
    <!--  补录按钮  -->
    <div v-if="showApprove" class="make-up-box">
      <span class="btn" @click="makeUpClick()">申请</span>
    </div>

    <!-- 选择同行人 -->
    <select-person
      :choose-value="personChooseSwitch"
      :multiple="true"
      :show-department-name="false"
      :show-phone-number="true"
      :visible="associatePopupVisible"
      position="bottom"
      title="同行人(多选)"
      @close="associatePopupVisible = false"
      @confirm="associatePopupConfirm"
    />

    <!-- 超时原因 -->
    <timeout-reason-dialog
      v-model="visible"
      @close="visible = false"
      @success="onSuccess"
    />

    <!-- 巡检编码提示 -->
    <InspectCodesTip ref="codeTip"></InspectCodesTip>

    <!-- 审批 -->
    <transcribe-apply v-if="canApprove" :info="pageInfo" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from "@/utils/axios";
import {
  getTaskInfoData,
  startTask,
  validTaskComplete,
  completeTask,
  uploadGeo,
  supplementEnd,
  getTaskPointData,
  getTaskPointInfoList
} from "@/api/psm/inspectionNew";
import selectPerson from "@/components/SelectPerson";
import dayjs from "dayjs";
import TimeoutReasonDialog from "@/views/pointCheckManagement/components/TimeoutReasonDialog";
import InspectCodesTip from "@/views/inspectionNew/components/InspectCodesTip";
import TranscribeApply from "@/views/inspectionManagement/inspectionTranscribe/TranscribeApply.vue";
import { getInspectConfig } from "@/api/psm/inspectionNew";
import { getSupplementAuth } from "@/views/inspectionManagement/api";

export default {
  name: "InspectionTranscribeDetail",
  components: {
    TimeoutReasonDialog,
    selectPerson,
    InspectCodesTip,
    TranscribeApply
  },
  data() {
    return {
      visible: false,
      canApprove: false,
      inspectConfig: { signInvalid: 0 },
      pageInfo: {
        id: "",
        patrolTaskId: "",
        name: "",
        department: "",
        departmentName: "",
        taskType: "",
        taskTypeName: "",
        shiftGroupId: "",
        shiftGroupName: "",
        executePersonId: "",
        executePersonName: "",
        validStartTime: "",
        validEndTime: "",
        actualStartTime: "",
        actualEndTime: "",
        partner: "",
        partnerName: "",
        cycleUnints: "",
        sortedStatus: "",
        recordStatus: "",
        recordStatusName: "",
        overtimeStatus: "",
        overtimeStatusName: "",
        patrolEndTimeFlag: "",
        patrolEndTimeFlagName: "",
        pointInfoList: []
      },
      associateDataList: [
        {
          value: 1,
          label: "123"
        },
        {
          value: 2,
          label: "444"
        },
        {
          value: 3,
          label: "555"
        }
      ],
      associatePopupVisible: false,
      taskList: [],
      baseInfoPackUp: true
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    }),
    supplementStatus() {
      return this.pageInfo.supplementStatus;
    },
    showApprove() {
      return this.supplementStatus === 5;
    },
    editable() {
      return this.supplementStatus === 3;
    },
    remainTime() {
      return dayjs(this.pageInfo.finalEndTime).diff(dayjs(new Date()), "m");
    },
    navBarRightText() {
      const obj = {
        0: "开始巡检",
        1: "完成巡检"
      };
      return obj[this.pageInfo.recordStatus] || "";
    },
    statusClassSwitch() {
      const obj = {
        0: "weikaishi",
        1: "jinxingzhong",
        2: "yiwancheng",
        3: "yiguoqi"
      };
      const statusClassName = obj[this.pageInfo.recordStatus] || "";
      return statusClassName ? `status-box-${statusClassName}` : "";
    },
    personChooseSwitch() {
      if (!this.pageInfo.partner) return [];
      const names = this.pageInfo.partnerName.split(",");
      const ids = this.pageInfo.partner.split(",");
      return ids.map((item, idx) => {
        return {
          value: item,
          label: names[idx]
        };
      });
    },
    cycleUnintsSwitch() {
      const obj = {
        0: "小时",
        1: "日",
        2: "周",
        3: "月",
        4: "年",
        5: "季度"
      };
      return obj[this.pageInfo.cycleUnints];
    },
    curPersonAuth() {
      return this.pageInfo?.executePersonGroupName?.some(
        v => v.id === this.userInfo.id
      );
    },
    endTimeSwitch() {
      return this.taskList?.some(i => !i.endTime);
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getTaskData();
    this.getSupplementAuth();
    getInspectConfig({ orgCode: this._orgCode }).then(res => {
      this.inspectConfig = res ?? { signInvalid: 0 };
    });
  },
  beforeDestroy() {
    window.QRCodeCallback = null;
    // 清空计时器
    this.clearLocalInterrelated();
  },
  methods: {
    ...mapActions(["logout"]),
    getSupplementAuth() {
      getSupplementAuth(this.$route.params.id).then(res => {
        this.canApprove = res;
      });
    },
    uploadLocalGeo() {
      if (this.pageInfo.recordStatus === 1) {
        if (!window.android || !window.android.getLocation) {
          return;
        }
        this.clearLocalInterrelated();
        window.localGeoCallBack = async (location, time, status) => {
          if (status === "1") {
            const date = new Date()
              .toLocaleDateString("zh-cn", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit"
              })
              .replace(/\//g, "-");
            const time = new Date().toLocaleTimeString("en-GB", {
              hour12: false
            });
            const localArr = location.split(",");
            const params = {
              executePersonId: this.userInfo.id,
              longitude: localArr[0],
              latitude: localArr[1],
              uploadTime: `${date} ${time}`
            };
            await uploadGeo(params);
          }
          // android那边获取经纬度的回调之后, 再继续获取新的位置
          window.localGeoFunc && window.localGeoFunc();
        };
        window.localGeoFunc = () => {
          window.localGeoTimeout = setTimeout(() => {
            window.androidH5.getLocation("localGeoCallBack");
          }, 5000);
        };
        window.localGeoFunc();
      }
    },
    clearLocalInterrelated() {
      window.localGeoFunc = null;
      window.localGeoCallBack = null;
      clearTimeout(window.localGeoTimeout);
      window.localGeoTimeout = null;
    },
    async getTaskData() {
      const info = await getTaskInfoData(this.$route.params.id);
      const pointInfoList = await getTaskPointInfoList({
        id: this.$route.params.id
      });
      info.pointInfoList = pointInfoList || [];
      info.supplementFileList = (info.supplementFileList || []).map(item => {
        item.url = this.getFileUrl(item.id, false);
        item.isImage = true;
        return item;
      });
      info.pointInfoList?.forEach(item => {
        item.checked = item.startTime ? true : false;
      });
      const findIdx = info.pointInfoList?.findIndex(item => !item.checked);
      if (findIdx !== -1) {
        info.pointInfoList &&
          info.pointInfoList[findIdx] &&
          (info.pointInfoList[findIdx].current = true);
      }
      this.pageInfo = info;
      this.taskList = info.pointInfoList;
      this.uploadLocalGeo();
    },
    onClickRight() {
      const obj = {
        1: "completeInspection"
      };
      const prop = obj[1] || "";
      prop && this[prop]();
    },
    startInspection() {
      this.associatePopupVisible = true;
    },
    async completeInspection() {
      this.baseInfoPackUp = false;
      await this.$refs.baseForm.validate();

      this.baseInfoPackUp = false;
      const params = {
        startTime: this.pageInfo.actualStartTime,
        endTime: this.pageInfo.actualEndTime,
        fileList: this.pageInfo.supplementFileList,
        recordId: this.pageInfo.id,
        partner: this.pageInfo.partner,
        executePersonId: this.userInfo.id
      };
      await supplementEnd(params);
      this.clearLocalInterrelated();
      this.$router.go(-1);
    },
    onSuccess() {
      this.clearLocalInterrelated();
      this.$router.go(-1);
    },
    async associatePopupConfirm(ids, list) {
      this.pageInfo.partner = ids.toString();
      this.pageInfo.partnerName = list.map(item => item.label).toString();
      if (this.pageInfo.supplementFlag && this.pageInfo.recordStatus === 1)
        return;
      try {
        const personIds = this.pageInfo.partner.toString();
        await startTask(this.pageInfo.id, personIds, this.userInfo.id);
        this.getTaskData();
      } catch (err) {
        console.log(err);
      }
    },
    topPathSwitch(idx) {
      const item = this.taskList[idx - 1];
      return item ? item.checked : false;
    },
    async whereToGo(pointId) {
      const recordId = this.pageInfo.id;
      const params = { patrolRecordId: recordId, pointId };
      const is = await getTaskPointData(params);
      const { signType } = this.pageInfo;
      if (is && is.length) {
        this.$router.push({
          name: "taskProject",
          params: { recordId, pointId, status: 1, signType }
        });
      } else {
        this.$router.push({
          name: "InspectNotContent",
          params: { recordId, pointId }
        });
      }
    },
    base64toFile(base64, filename) {
      let arr = base64.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    uploadFile(file) {
      const filePath = window.globalConfig.VUE_APP_FILE_URL + "/file/upload";
      const fd = new FormData();
      fd.append("files", file);
      return axios.post(filePath, fd);
    },
    toDetail({ pointId }) {
      if (!this.editable) {
        return false;
      }
      this.$router.push({
        name: "taskProject",
        params: {
          recordId: this.pageInfo.id,
          pointId: pointId,
          status: 1, // 补录的固定到可编辑
          signType: this.pageInfo.signType
        },
        query: {
          onlyLeakCheckStatus: 1
        }
      });
    },
    checkItemNumSwitch(num, startTime) {
      if (!startTime && !this.pageInfo.supplementTime) {
        return "--";
      }
      return num || 0;
    },
    switchAccountClick() {
      if (!this.editable) {
        return false;
      }
      this.$dialog
        .confirm({
          className: "custom-confirm-dialog",
          title: "提醒",
          message: "确认退出当前账号？",
          messageAlign: "left",
          confirmButtonText: "确定",
          confirmButtonColor: "#1676ff"
        })
        .then(() => {
          this.logout();
        });
    },
    makeUpClick() {
      this.$router.push({
        name: "inspectionTranscribeLeakReason",
        params: {
          id: this.pageInfo.id
        },
        query: {
          supplementApprove: this.pageInfo.supplementApprove || 0
        }
      });
    },
    getFileUrl(id, downloadFlag = false) {
      return `${window.globalConfig.VUE_APP_FILE_URL}/file/download?downloadFlag=${downloadFlag}&fileName=${id}`;
    },
    associateClick() {
      if (this.pageInfo.supplementFlag && this.pageInfo.recordStatus === 1) {
        this.associatePopupVisible = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  padding-top: 58px;

  .note {
    text-align: center;
    line-height: 30px;
    color: #fb7709;
    font-size: 12px;
    background-color: rgba(#ffae13, 0.1);
    margin-bottom: 16px;
  }

  .title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 8px;

    .left-box {
      flex: 1;
    }

    .title {
      font-size: 16px;
      font-weight: 500;
      color: #2e2e4d;
      line-height: 22px;
      vertical-align: middle;
    }

    .overtime-tag {
      display: inline-block;
      height: 16px;
      line-height: 16px;
      font-size: 11px;
      padding: 0 6px;
      color: #fff;
      background-color: #f13030;
      text-align: center;
      border-radius: 3px;
      margin: 0 0 0 8px;
      vertical-align: middle;
    }

    .make-up-tag {
      display: inline-block;
      height: 16px;
      line-height: 16px;
      font-size: 11px;
      padding: 0 6px;
      color: #fff;
      background-color: #3683f4;
      text-align: center;
      border-radius: 3px;
      margin: 0 0 0 8px;
      vertical-align: middle;
    }

    .status-box {
      width: 54px;
      height: 16px;
      line-height: 16px;
      font-size: 11px;
      text-align: center;
      border: 1px solid;
      border-radius: 3px;
    }

    .status-box-weikaishi {
      color: #dea148;
      background: #ffecd1;
      border-color: #dea148;
    }

    .status-box-jinxingzhong {
      color: #3683f4;
      background: #e1edff;
      border-color: #3683f4;
    }

    .status-box-yiwancheng {
      color: #8eb433;
      background: #f5ffdc;
      border-color: #8eb433;
    }

    .status-box-yiguoqi {
      color: #5c7ba2;
      background: #e5eaf0;
      border-color: #5c7ba2;
    }
  }

  .date-box {
    display: inline-block;
    height: 16px;
    line-height: 16px;
    background: #eaeff5;
    border-radius: 2px;
    font-size: 11px;
    font-weight: 400;
    color: #3b4664;
    padding: 0 6px;
    margin: 0 0 16px;
  }

  .detail-box.pack-up {
    height: 100px;
  }

  .detail-box {
    margin: 0 0 8px;
    overflow: hidden;

    .detail-item {
      display: flex;
      align-items: center;
      padding: 0 0 8px;
      line-height: 20px;
      font-size: 14px;
      font-weight: 400;

      .label {
        color: #8c8f97;
      }

      .text {
        flex: 1;
        word-break: break-word;
        color: #2e2e4d;
      }

      .executor {
        flex: initial;
        padding: 2px 6px;
        background: #eaeff5;
        border-radius: 2px;
      }

      .associate {
        .van-cell {
          border: 1px solid #c7c9d0;
          padding: 2px 4px;
        }
      }

      .switch-account {
        display: inline-block;
        font-size: 12px;
        margin: 0 10px 0;
        padding: 4px 6px 3px;
        background-color: #7f7f7f;
        border-radius: 15px;
        color: #fff;
      }
    }

    ::v-deep {
      .c-van-field::before {
        left: -8px;
      }

      .c-van-field {
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;

        .c-label {
          font-size: 14px;
          color: #8c8f97;
        }

        .c-data-tiem {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  .exp-box {
    text-align: center;
  }
}

.list-box {
  min-height: calc(100vh - 301px - 46px);
  padding: 16px;
  background-color: rgba(236, 238, 242, 0.5);

  .item {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 8px 0px #dbdde4;
    font-size: 0;
    height: 86px;

    .index-box {
      display: inline-block;
      padding: 0 8px;
      text-align: center;
      z-index: 2;
      height: 100%;

      .path-box {
        font-size: 0;
        height: calc(100% / 2 - 12px);

        .path {
          display: inline-block;
          height: 100%;
          width: 3px;
          background-color: #eaeff5;
        }

        .checked-path {
          background-color: #1676ff;
        }
      }

      .index-radius {
        width: 24px;
        height: 24px;
        line-height: 24px;
        box-sizing: border-box;
        border-radius: 50%;
        font-size: 16px;
        font-weight: 500;
        color: #2e2e4d;
        background-color: #eaeff5;
      }

      .checked-radius {
        color: #ffffff;
        line-height: 25px;
        background-color: #1676ff;
      }

      .current-radius {
        line-height: 23px;
        background-color: #fff;
        border: 2.01px solid #1676ff;
      }
    }

    .task-info-box {
      font-size: 14px;
      display: inline-block;
      width: calc(100% - 44px);
      vertical-align: top;
      padding: 12px 4px 10px 0;
      overflow: hidden;

      .name-box {
        display: flex;
        justify-content: space-between;
        line-height: 20px;

        .name {
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #2e2e4d;
        }

        .iconfont-Line {
          color: #aeb3c0;
        }
      }

      .date-box {
        font-size: 12px;
        font-weight: 400;
        color: #8c8f97;
        line-height: 17.01px;
        padding: 2px 0 8px;
      }

      .item-num-box {
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        color: #8c8f97;
        height: 17.01px;
        line-height: 17.01px;

        .num-style {
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
        }

        .item-num {
          color: #2e2e4d;
        }

        .total {
          width: 97px;
        }

        .normal,
        .abnormal,
        .leakage {
          width: 67px;
        }

        .abnormal.active {
          label {
            background: rgba(241, 48, 48, 0.2);
            border-radius: 2px;
            color: #f13030;
            padding: 1px;
          }

          .item-num {
            color: #f13030;
          }
        }

        .leakage.active {
          label {
            background: rgba(233, 118, 23, 0.2);
            border-radius: 2px;
            color: #e97617;
            padding: 1px;
          }

          .item-num {
            color: #e97617;
          }
        }
      }
    }
  }

  .item-path {
    font-size: 0;
    height: 8.01px;
    margin: 0 0 0 18.5px;
    z-index: 2;

    .path {
      display: inline-block;
      width: 3px;
      height: 100%;
      background-color: #eaeff5;
    }

    .checked-path {
      background-color: #1676ff;
    }
  }
}

.make-up-box {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0 0 16px;
  text-align: center;

  .btn {
    display: inline-block;
    width: 100px;
    height: 44px;
    line-height: 44px;
    background: #1676ff;
    border-radius: 24px;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
  }
}
</style>
