<!--补录申请-->
<template>
  <div>
    <div ref="transcribe" class="transcribe-apply">
      <h2>
        <span @click="onShowReason">补录申请</span
        ><van-icon v-if="show" name="cross" @click="onCloseReason" />
      </h2>
      <p v-if="show" class="apply-content">
        补录原因：<br />
        {{ reason }}
      </p>
      <div class="button-wrapper">
        <van-button @click="onOp(0)">驳回</van-button>
        <van-button type="info" @click="onOp(1)">同意</van-button>
      </div>
    </div>
    <van-overlay :show="show" />
  </div>
</template>

<script>
import { approvalInspectSupplement } from "@/views/inspectionManagement/api";
import { operateDialog, operateMessage } from "@/utils";

export default {
  name: "TranscribeApply",
  props: {
    info: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      show: true
    };
  },
  computed: {
    reason() {
      return this.info.supplementReason || "--";
    }
  },
  async mounted() {
    await this.$nextTick();
    this.onShowReason();
  },
  methods: {
    onShowReason() {
      this.show = true;
      this.$refs.transcribe.style.bottom = "0px";
    },
    onCloseReason() {
      this.show = false;
      this.$refs.transcribe.style.bottom = "-280px";
    },
    async onOp(approvalOpinion = 1) {
      const data = {
        id: this.$route.params.id,
        approvalOpinion,
        approvalUser: this.userInfo.id,
        approvalContent: ""
      };
      const r = await approvalInspectSupplement(data);
      operateMessage(!!r, "操作");
      if (r) {
        this.$router.push("/inspection-management-dispatch");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.transcribe-apply {
  position: fixed;
  z-index: 10;
  bottom: -280px;
  width: 100%;
  height: 398px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #e1e3e8;
  padding: 16px;
  transition: all 0.3s;
  h2 {
    position: relative;
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #2e2e4d;
    margin-bottom: 20px;
    ::v-deep {
      .van-icon {
        position: absolute;
        right: 0;
      }
    }
  }
  .apply-content {
    line-height: 22px;
  }
  .button-wrapper {
    width: calc(100% - 32px);
    position: fixed;
    left: 0;
    bottom: 16px;
    display: flex;
    padding: 0 16px;
    justify-content: space-between;
    background: #ffffff;
    ::v-deep {
      .van-button {
        width: 47%;
        height: 44px;
        border-radius: 27px;
      }
    }
  }
}
</style>
